<template>
  <div style="margin: 20px;">
    <Form ref="formLogin" :label-width="80" :model="formLogin" :rules="ruleLogin">
      <FormItem prop="branchId" label="目标应用">
        <Select v-model="formLogin.branchId">
          <Option v-for="item in branchs" :value="item.id" :key="item.id">{{ item.branchname }}
          </Option>
        </Select>
      </FormItem>
      <FormItem prop="mobile" label="手机号码">
        <Input type="tel" placeholder="手机号码" v-model="formLogin.mobile">
        <Icon type="md-phone-portrait" slot="prefix" />
        </Input>
      </FormItem>
      <FormItem prop="smsCode" label="验证码">
        <Input placeholder="从手机获取的5位验证码" v-model="formLogin.smsCode" search
          :enter-button="time > 0 ? time + '秒后重试' : '发送验证码'" @on-search="sendSMS">
        <Icon type="logo-angular" slot="prefix" />
        </Input>
      </FormItem>
      <FormItem prop="realname" label="姓名" v-if="modalType == 1">
        <Input placeholder="姓名" v-model="formLogin.realname">
        <Icon type="ios-contact" slot="prefix" />
        </Input>
      </FormItem>
      <FormItem prop="password" :label="modalType == 1 ? '密码' : '新密码'">
        <Input type="text" placeholder="密码（8~32位，需要至少同时包含字母和数字）" v-model="formLogin.password">
        <Icon type="ios-unlock" slot="prefix" />
        </Input>
      </FormItem>
      <FormItem>
        <Button type="primary" :loading="loginloading" @click="submit" class="loginbtn"
          style="float:right;width:200px;margin-top: 10px;" size="large">
          {{ modalType == 1 ? "注册" : "更新密码" }}
        </Button>
      </FormItem>
    </Form>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    modalType: { type: Number, default: 1 },
    oemInfo: { type: Object, default: () => { } },

  },
  watch: {
    oemInfo: function (val) {
      if (val && val.branchs) {
        this.branchs = val.branchs
        if (val.branchs.length == 1)
          this.formLogin.branchId = this.branchs[0].id
      }
    },
    modalType: function (val) {
      this.time = 0;
    },
  },
  data() {
    return {
      time: 0,
      loginloading: false,
      branchs: [],
      formLogin: {
        mobile: "",
        password: "",
        realname: "",
        smsCode: "",
        branchId: null,
      },
      ruleLogin: {
        mobile: [{ required: true, message: "手机号不能为空。", trigger: "blur" }],
        password: [{ required: true, message: "密码不能为空。", trigger: "blur" }],
        smsCode: [{ required: true, message: "验证码不能为空。", trigger: "blur" }],
        branchId: [{ type: "number", required: true, message: "请选择目标应用。", trigger: "change" }],
      },
    }
  },
  mounted() {

  },
  methods: {
    //倒计时
    preTime() {
      if (this.time-- > 0) {
        this.timer = setTimeout(this.preTime, 1000)
      }
    },
    sendSMS() {
      if (this.time > 0) {
        return;
      }
      if (this.modalType == 1 && this.$core.ifNULLtoDF(this.formLogin.branchId, null) == null) {
        this.$Message.error({
          background: true,
          content: "请选择目标应用",
          duration: 3,
        })
        return
      }
      if (this.$core.ifNULLtoDF(this.formLogin.mobile, null) == null) {
        this.$Message.error({
          background: true,
          content: "请输入手机号码",
          duration: 3,
        })
        return
      }
      let regMobile = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
      if (!regMobile.test(this.formLogin.mobile)) {
        this.$Message.error({
          background: true,
          content: "手机号码格式有误",
          duration: 3,
        })
        return
      }

      this.$post({
        url: "/gateway/api/sms/sendSmsCaptcha",
        data: {
          branchId: this.formLogin.branchId,
          phoneNumbers: this.formLogin.mobile,
          templateCode: this.modalType == 1 ? "register" : "forget",//需改造为后台读取SMS配置
        },
        success: res => {
          //   console.log(res);
          if (res && res.code == "200") {
            if (res.data && res.data.err == true) {
              this.$Message.error({
                background: true,
                content: res.data.desc || "发送失败，请重试。",
                duration: 4,
              })
            } else {
              this.time = 60
              this.preTime()
            }

          } else {
            this.$Message.error({
              background: true,
              content: "发送失败，请重试。",
              duration: 3,
            })

          }
        },
      })
    },
    submit() {
      this.$refs.formLogin.validate(valid => {
        if (valid) {
          let regMobile = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
          if (!regMobile.test(this.formLogin.mobile)) {
            this.$Message.error({
              background: true,
              content: "手机号码格式有误",
              duration: 3,
            })
            return
          }
          var testPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*\d)[\s\S]{8,32}$/
          if (!testPassword.test(this.formLogin.password)) {
            this.$Message.error({
              background: true,
              content: "密码不符合规则。",
              duration: 3,
            })
            return;
          }
          if (this.modalType == 1) {
            this.regOk();
          } else {
            this.saveForgetPwd()
          }
        }
      })
    },
    async regOk() {
      let data = {
        loginType: "reglogin", //手机号码+验证码+密码（登陆并注册）
        username: this.formLogin.mobile,
        password: this.formLogin.password,
        realname: this.formLogin.realname,
        captchaCode: this.formLogin.smsCode,
        branchId: this.formLogin.branchId,
      }
      this.logining(data)
    },
    // 修改密码
    saveForgetPwd() {
      let data = {
        loginType: "forgetPwd",
        username: this.formLogin.mobile,
        password: this.formLogin.password,
        captchaCode: this.formLogin.smsCode,
        branchId: this.formLogin.branchId,
      }
      this.logining(data)
    },

    logining(data) {
      data = {
        ...data,
        client_id: this.oemInfo.client_id,
        client_secret: this.oemInfo.password,
        scope: this.oemInfo.scope,
        grant_type: this.oemInfo.grant_type,
      }
      this.loginloading = true
      this.$post({ url: "/gateway/oauth/login", data: data, header: { other: "noSend" } })
        .then(async res => {
          this.loginloading = false
          var desc = res.desc || "验证失败，请重试。";

          if (desc.indexOf("密码修改成功") > -1 || desc.indexOf("注册成功") > -1 || desc.indexOf("手机号已是本平台用户") > -1) {
            this.$Message.success({
              background: true,
              content: desc,
              duration: 3,
            })
            this.$refs.formLogin.resetFields();
            this.$emit("close")
          } else {
            this.$Message.error({
              background: true,
              content: desc,
              duration: 3,
            })
          }
        })
        .catch(err => {
          that.loginloading = false
          this.$Message.error({
            background: true,
            content: "验证出错，请重试。",
            duration: 3,
          })
        })
    }
  },
}
</script>
<style lang="less" scoped></style>
